#personalNote {
  height: 100px;
  width: 100%;
  background: rgba(255, 255, 255, 0.25) !important;
  color: white;
}

.helper-text.shake {
  color: #ff0000;
  animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
