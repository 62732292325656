@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&family=Lora:wght@400;500;600&display=swap');

.cookie-notice-overlay {
  position: fixed;
  bottom: 20px;
  inset-inline-end: 20px;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.cookie-notice {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  color: white;
  padding: 20px;
  border-radius: 16px;
  max-width: 350px;
  width: 100%;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 1.4;
}

.cookie-notice h2 {
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 0.5em;
  margin-top: 0;
  font-size: 18px;
  font-weight: 500;
}

.cookie-notice button {
  margin-block-start: 10px;
  margin-inline-end: 10px;
  padding: 8px 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.cookie-notice button:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-1px);
}

.cookie-preferences {
  margin-top: 15px;
}

.cookie-preferences label {
  display: block;
  margin-block: 10px 0;
  font-size: 14px;
}

.cookie-preferences input[type="checkbox"] {
  margin-inline-end: 8px;
}

.cookie-notice-footer {
  margin-block-start: 15px;
  font-size: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-block-start: 10px;
  color: rgba(255, 255, 255, 0.8);
}

.cookie-notice-footer a {
  color: white;
  text-decoration: none;
  margin-inline-end: 10px;
  transition: color 0.2s;
}

.cookie-notice-footer a:hover {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: underline;
}

.dyslexic-font .cookie-notice * {
  font-family: 'OpenDyslexic', sans-serif !important;
}

@media (max-width: 480px) {
  .cookie-notice-overlay {
    bottom: 10px;
    right: 10px;
    left: 10px;
  }

  .cookie-notice {
    max-width: none;
  }
}