/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* App container */
.app {
  min-height: 100vh;
  background: linear-gradient(135deg, #40e0d0, #9370db, #ff8c00);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Navigation */
.nav-container {
  background-color: black;
  width: 100%;
}

.navbar {
  max-width: 1200px;
  margin: 0 auto;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  z-index: 1000;
}

.nav-logo {
  flex: 0 0 160px;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 1.125rem;
}

.logo-box {
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 4px;
  margin-right: 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-inner {
  width: 80%;
  height: 80%;
  background: black;
  border-radius: 2px;
}

.nav-links {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 32px;
}

.nav-links button {
  background: none;
  border: none;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
}

.nav-links button:hover {
  opacity: 0.8;
}

.nav-actions {
  flex: 0 0 160px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.try-app {
  background: none;
  border: none;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
}

.download-btn {
  background: #2563eb;
  color: white;
  border: none;
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.download-btn:hover {
  background: #1d4ed8;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 80px 1rem 0;
  width: 100%;
  box-sizing: border-box;
}

h1 {
  color: white;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.subtitle {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  line-height: 1.4;
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  margin: 0rem 3rem 3rem 3rem;
}

.cta-button, .watch-demo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
}

.cta-button {
  background: white;
  color: black;
  justify-content: center;
}

.cta-button:hover {
  opacity: 0.9;
}

.watch-demo {
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.watch-demo:hover {
  background: rgba(0, 0, 0, 0.6);
}

.play-icon {
  width: 20px;
  height: 20px;
}

.demo-time {
  font-size: 0.875rem;
  opacity: 0.8;
  margin-left: 0.25rem;
}

/* Features Section */
.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 10rem;
  max-width: 1200px;
  width: 100%;
  margin: 0rem 3rem 3rem 3rem;
  padding: 0 1rem;
}

.feature-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 2rem;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.2s;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.feature-left {
  flex: 1;
}

.feature-right {
  flex: 3;
}

.feature-right img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.feature-icon {
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 12px;
  margin-bottom: 1.5rem;
}

.feature-card h3 {
  color: white;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.feature-card p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

.try-abal-section {
  width: 45%;
  margin: 0rem 3rem 3rem 3rem;
  text-align: center;
  padding: 4rem 2rem;
  backdrop-filter: blur(20px);
  border-radius: 24px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  color: white;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.try-abal-section:hover {
  transform: translateY(-4px);
  background: rgba(0, 0, 0, 0.4);
  border-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 12px 48px rgba(0, 0, 0, 0.2);
}

.try-abal-section h2 {
  font-size: 48px;
  margin-bottom: 24px;
}

.footer {
  background: #000;
  color: white;
  padding: 20px 20px 20px;
  z-index: 0;
  position: relative;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.footer-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
}

.footer-email {
  color: #666;
  text-decoration: none;
}

.footer-links {
  display: flex;
  gap: 80px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer-column a {
  color: white;
  text-decoration: none;
  transition: color 0.2s;
}

.footer-column a:hover {
  color: #666;
}

.footer-bottom {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  border-top: 1px solid #333;
  display: flex;
  justify-content: space-between;
  color: #666;
}

.video-container {
  max-width: 1200px;
  width: 100%;
  margin: 2em auto;
  padding: 0 1rem;
}

.video-wrapper {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
}

.video-wrapper iframe {
  max-width: 100%;
  border-radius: 8px;
}

.language-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}

.language-item {
  font-size: 0.9rem;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}


.classics-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}

.classic-item {
  font-size: 0.9rem;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}


.language-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: 0.5s;
}

.language-item:hover::before {
  left: 100%;
}

#request-language, #explore-library, #try-for-free {
  margin-top: 1rem;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
}

#try-for-free {
  width: 50%;
}

#request-language:hover, #explore-library:hover, #try-for-free:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-1px);
}

#request-language:active, #explore-library:active, #try-for-free:active {
  transform: translateY(0px);
}

.classics-columns {
  display: flex;
  gap: 2rem;
}

.classics-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.classic-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent 10%,
    rgba(255, 255, 255, 0.3) 50%,
    transparent 90%
  );
  border-radius: 12px;
  transition: 0.5s;
}

.classic-item:hover::before {
  left: 100%;
}

.classic-author {
  font-size: 0.7rem;
  color: #e9e9e9;
  position: relative;
  z-index: 1;
}

.classic-languages {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 0.25rem;
}

@media (max-width: 900px) {

  .nav-logo {
    flex: 0 0 auto;
  }

  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: black;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    z-index: 1000;
  }

  .nav-links button {
    width: 100%;
    padding: 0.5rem;
  }

  .nav-actions {
    flex: 0 0 auto;
    margin-left: auto;
  }

  h1 {
    font-size: 3rem;
  }
  
  .subtitle {
    font-size: 1.25rem;
  }
  
  .cta-buttons {
    flex-direction: column;
  }
/* 
  .features-container {
    margin-top: 4rem;
    grid-template-columns: 1fr;
  }
  
  .feature-card {
    flex-direction: column;
  }
  
  .feature-right img {
    margin-top: 1.5rem;
  } */

  .video-wrapper {
    padding: 1rem;
  }
  
  .video-wrapper iframe {
    height: 240px;
  }
}

/* Add hamburger menu styles */
.hamburger {
  display: none;  /* Hidden by default on desktop */
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 0.5rem;
  margin-left: 1rem;
}


/* Add these new styles */
.floating-char {
  position: fixed;
  color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
  user-select: none;
  bottom: -20px;
}

@keyframes float-rise-clockwise {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 0.3;
  }
  100% {
    transform: translateY(-120vh) rotate(360deg);
    opacity: 0;
  }
}

@keyframes float-rise-counterclockwise {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 0.3;
  }
  100% {
    transform: translateY(-120vh) rotate(-360deg);
    opacity: 0;
  }
}

.rotate-clockwise {
  animation: float-rise-clockwise 6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.rotate-counterclockwise {
  animation: float-rise-counterclockwise 6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Update existing style to ensure content stays above floating characters */
.main-content {
  position: relative;
  z-index: 1;
}

@media (max-width: 900px) {
  .hamburger {
    display: block; 
  }

  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: black;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    z-index: 1000;
    display: none; 
  }

  .nav-links.show {
    display: flex; 
  }

  .nav-links button {
    width: 100%;
    padding: 0.5rem;
  }
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {

  .try-abal-section {
    width: 90%;  /* Increased from 45% */
    padding: 1rem 0rem 1rem 0rem;
    margin-bottom: 2rem;
  }

  .try-abal-section h2 {
    font-size: 1.4em;  /* Smaller font size */
    white-space: nowrap;  /* Prevent text wrapping */
  }

  #try-for-free {
    width: 50%;
    padding: 0.5rem 0rem 0.5rem 0rem;
    margin-top: 0rem;
  }

  .language-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
  /* Mobile Responsive Adjustments */
@media (max-width: 600px) {

  /* Footer mobile adjustments */
  .footer {
    padding: 30px 15px 15px;  /* Reduced padding */
  }

  .footer-content {
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 30px;  /* Reduced margin */
  }

  .footer-links {
    gap: 2rem;  /* Reduced gap */
    justify-content: space-between;
  }

  .footer-column {
    gap: 0.8rem;  /* Reduced gap */
  }

  .footer-left {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
  }

  .language-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.language-selector {
  padding: 4px 24px 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: transparent;
  color: inherit;
  font-size: 0.9rem;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 4px center;
  background-size: 16px;
}

.language-selector option {
  background: #fff;
  color: #000;
}

/* For dark theme footer */
.footer .language-selector {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
}