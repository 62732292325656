.modal-overlay {
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  color: white;
  padding: 30px;
  border-radius: 16px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    z-index: 1000;
  max-height: 80vh;
  overflow-y: auto;
}

.modal-content h2 {
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-block-end: 0.5em;
  margin-block-start: 0;
  font-size: 24px;
  font-weight: 500;
}

.form-group {
  margin-block-end: 20px;
}

.form-group label {
  display: block;
  margin-block-end: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  color: white;
  font-size: 14px;
  transition: all 0.2s ease;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.15);
}

.form-group select[multiple] {
  height: auto;
  min-height: 120px;
}

.form-group select option {
  background: #2a2a2a;
  padding: 8px;
}

.form-group small {
  display: block;
  margin-top: 6px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.modal-actions button {
  padding: 8px 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.modal-actions button:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-1px);
}

.modal-actions button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.cancel-button {
  background: rgba(255, 255, 255, 0.1) !important;
}

.submit-button {
  background: rgba(255, 255, 255, 0.25) !important;
}

.close-button {
  min-width: 100px;
}

#personalNote {
  height: 100px;
  width: 100%;
  background: rgba(255, 255, 255, 0.25) !important;
  color: white;
}

.modal-content select {
  margin: 0 !important;
}

@media (max-width: 480px) {
  .modal-overlay {
    align-items: flex-center;
    padding-top: 20px;
  }

  .modal-content {
    width: 95%;
    padding: 20px;
    margin: 0 10px;
    max-height: calc(100vh - 100px);
  }

  .modal-actions {
    flex-direction: column;
  }

  .modal-actions button {
    width: 100%;
  }
}

.dyslexic-font .modal-content * {
  font-family: 'OpenDyslexic', sans-serif !important;
}
