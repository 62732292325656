.pricing-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    width: 100%;
    margin-block: 2rem;
    margin-inline: auto;
    padding-inline: 1rem;
  }
  
  .pricing-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    padding: 2rem;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: transform 0.2s;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .pricing-card.featured {
    background: rgba(255, 255, 255, 0.15);
    transform: scale(1.05);
    border-color: rgba(255, 255, 255, 0.3);
  }
  
  .popular-badge {
    position: absolute;
    inset-block-start: -12px;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    background: #2563eb;
    color: white;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .pricing-card h3 {
    color: white;
    font-size: 1.5rem;
    margin: 0;
  }
  
  .price {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.25rem;
  }
  
  .amount {
    color: white;
    font-size: 3rem;
    font-weight: bold;
  }
  
  .period {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1rem;
  }
  
  .features-list {
    list-style: none;
    padding-inline: 0;
    margin-inline: 0;
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .select-plan {
    background: #2563eb;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-block-start: auto;
  }
  
  .select-plan:hover {
    background: #1d4ed8;
  }
  
  @media (max-width: 900px) {
    .pricing-card.featured {
      transform: none;
    }
    
    .pricing-container {
      grid-template-columns: 1fr;
      max-width: 400px;
    }
  }