.work-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
}

.work-buttons {
  background: white;
  color: black;
  justify-content: center;
}

.work-buttons:hover {
  opacity: 0.9;
}

/* Features Section */
.work-container {
  margin: 0 auto;
  padding: 0rem 0rem 2rem 0rem;
}

.work-card {
  width: 100%;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding-block: 2rem;
  padding-inline: 2rem;
  margin-block: 1em;
  margin-inline: auto;
  text-align: start;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.2s;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.work-left {
  flex: 1;
}

.work-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.work-right img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.work-card:hover {
  transform: translateY(-4px);
}

.work-icon {
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 12px;
  margin-bottom: 1.5rem;
}

.work-card h3 {
  color: white;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.work-card p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

@media (max-width: 768px) {
  .work-container {
    gap: 0rem;
  }

  .work-card {
    flex-direction: column;
    text-align: center;
  }

  .work-left, .work-right {
    flex: 1;
    width: 100%;
  }
}
