.selected-languages {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.selected-language-tag {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.25) !important;
  padding: 4px 8px;
  border-radius: 4px;
  gap: 8px;
}

.remove-language-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 4px;
  font-size: 16px;
  color: #eaeaea;
}

.language-options {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.language-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid #eee;
}

.language-option:last-child {
  border-bottom: none;
}

.add-language-btn {
  background: #f8f9fa;
  border: none;
  color: #0066cc;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
}

.add-language-btn:hover {
  background: #e9ecef;
  border-radius: 4px;
}

