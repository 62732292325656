/* Font Declarations */
@font-face {
  font-family: 'OpenDyslexic';
  src: url('./fonts/OpenDyslexic/OpenDyslexic-Regular.otf') format('otf'),
        url('./fonts/OpenDyslexic/OpenDyslexic-Regular.woff') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Base Container Styles */
.parallel-text-history {
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Merriweather', serif;
  font-size: 1vw;
  line-height: 1.6;
  color: #4a3f35;
}

/* Sentence Pair Layout */
.sentence-pair {
  display: flex;
  justify-content: space-between;
}

.sentence-pair:nth-child(odd) {
  background-color: #e0d8c0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.sentence-pair:nth-child(even) {
  background-color: #f4efe1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Language Column Styles */
.language-one,
.language-two {
  flex: 1;
  padding: 0.5em;
  position: relative;
  margin-right: 0;
  border: none;
  text-align: center;
}

.language-one {
  border-right: 1px solid #b8a88a;
}

.language-two {
  border-left: 1px solid #b8a88a;
}

/* Sentence and Word Styles */
.sentence {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  text-align: center;
  font-size: 2vh;
}

.sentence.has-pronunciation {
  margin-bottom: 0.5em;
}

.word-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.3em;
  cursor: default;
  vertical-align: baseline;
}

.word-wrapper.has-pronunciation {
  margin-bottom: 0.5em;
}

.word-wrapper.toggled {
  background-color: #f98aff;
  border-radius: 4px;
  padding: 0.1em 0.3em;
  transition: background-color 0.3s ease;
}

.word-wrapper.toggled:hover {
  background-color: #ffbebe;
}

/* Text Elements */
.word {
  text-align: center;
  padding: 0.1em 0.2em;
  line-height: 1.2;
  font-weight: normal !important;
}

.punctuation {
  align-self: flex-end;
  margin-right: 0.1em;
}

.pronunciation {
  font-size: calc(0.7em + 0.1vw);
  color: #666;
  text-align: center;
  padding: 0.1em 0.2em;
  margin-bottom: 0.2em;
  white-space: nowrap;
}

/* Highlighting Styles */
.highlight-wrapper {
  background-color: #77b6ff;
  border-radius: 2px;
  transition: background-color 0.3s ease;
}

.highlight-wrapper:hover {
  background-color: #77b6ff;
}

/* Accessibility Styles */
.dyslexic-font * {
  font-family: 'OpenDyslexic', sans-serif !important;
}

.parallel-text-history.dyslexic-font {
  font-family: 'OpenDyslexic', sans-serif;
}

/* Layout Variations */
.parallel-text-history.justify-left .sentence { justify-content: flex-start; }
.parallel-text-history.justify-right .sentence { justify-content: flex-end; }
.parallel-text-history.center .sentence { justify-content: center; }

/* Interlace Layout */
.parallel-text-history.interlace .sentence-pair {
  flex-direction: column;
}

.parallel-text-history.interlace .language-one,
.parallel-text-history.interlace .language-two {
  padding: 0.25em 0.5em;
}

/* Furigana Styles */
.furigana-text {
  font-size: calc(0.6em + 0.1vw);
  line-height: 1;
  text-align: center;
  margin-bottom: 0.2em;
  color: #666;
  transition: opacity 0.3s ease;
}

/* Animations */
@keyframes slideIn {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .parallel-text-history {
    font-size: 16px;
  }

  .sentence-pair {
    flex-direction: column;
  }

  .pronunciation {
    font-size: 12px;
  }

  .furigana-text {
    font-size: 11px;
  }
}
