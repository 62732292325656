#home-title {
    font-size: 2.5rem;
}

.feature-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.categories-preview {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    color: #ffffff;
    gap: 1.5rem;
    padding: 1rem;
    width: 100%;
}

.difficulty-group {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 1rem;
}

.difficulty-group h4 {
    margin: 0 0 0.5rem 0;
    font-size: 1.1rem;
}

.difficulty-group ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.difficulty-group li {
    margin: 0.5rem 0;
    font-size: 0.9rem;
}

.feature-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: 100%;
}

.feature-left, .feature-right {
    width: 100%;
}