.mission-content {
    max-width: 800px;
    margin-block: 2rem;
    margin-inline: auto;
    padding-inline: 1rem;
}

.mission-content p {
    color: #f0f0f0;  
    margin-block-end: 1.5rem;
    line-height: 1.6;
    font-size: 1.1rem;
}
