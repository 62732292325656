.features-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1200px;
  width: 100%;
  margin-block: 0rem;
  margin-inline: 0rem;
  padding-block: 0rem 2rem;
  padding-inline: 0rem;
}

.feature-card {
  display: flex;
  align-items: center;
  gap: 2rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.feature-start {
  flex: 1;
}

.feature-end {
  flex: 3;
}

.feature-end img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .features-container {
    gap: 0rem;
    margin-block: 0rem;
    margin-inline: 0rem;
    padding-block: 0rem 2rem;
    padding-inline: 0rem;
  }

  .feature-card {
    flex-direction: column;
    text-align: center;
  }

  .feature-start, .feature-end {
    flex: 1;
    width: 100%;
  }
}

#explore-library:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  opacity: 0.7;
  border: none;
}

#explore-library:disabled:hover {
  background-color: #cccccc;
  transform: none;
  box-shadow: none;
}